import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { ProcessComponent } from './process/process.component';
import { ServicesComponent } from './services/services.component';


const routes: Routes = [
  { path: '', 
    component: HomeComponent 
  },
  {
    path: 'about',
    component: AboutComponent
  },
  {
    path: 'process',
    component: ProcessComponent
  },
  {
    path: 'services',
    component: ServicesComponent
  }
];
export default Routes;

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
