<app-home-banner></app-home-banner>
    <main>
        <div class="clearfix"></div>
        <section class="about">
            <div class="container">
                <div class="row">
                    <div class="col span_12">
                        <h2 class="topheading">About Servercenter</h2>
                        <div class="subcontainer">
                            <p class="bold">Entrenched in 2016, Server Center was developed out of the need to meet the modern day requirements of the digital business world. We provide comprehensive services such as web development, website design, lead generation, digital marketing, etc., which are the essential ingredients for the growth of your business.</p>
                            <p class="light">Our approach is driven by the zeal to produce the maximum possible output in short span of time. Our expert think tanks have formed such a flexible structure that customers can use our in-house expertise to make instant modifications to their system. Unlike the other service providers, we provide real time solution for application and software development, time saved is money saved. Our core foundation is to provide the result-oriented cutting edge technological solutions to our customers at inexpensive prices. We have a global acclamation with our loyal customers located across the world. We have a corporate presence in Alberta, Toronto, Calgary, Canada and New Delhi, India. Server Center is a subsidiary of VOIS Inc., which is an industry pioneer in telecommunications and IT services.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div class="clearfix"></div>

        <app-our-services></app-our-services>


        <!-- <section class="ourservices">
            <div class="container">
                <div class="row">
                    <article class="col span_12">
                        <div>
                            <ul class="nav nav-tabs" role="tablist">
                                <li role="presentation" class="active"><a href="#digital-marketing" aria-controls="digital marketing" role="tab" data-toggle="tab">
                                        <h1>DIGITAL MARKETING</h1>
                                    </a></li>
                                <li role="presentation"><a href="#web-development" aria-controls="web development" role="tab" data-toggle="tab">
                                        <h2>Web DEVELOPMENT</h2>
                                    </a></li>
                                <li role="presentation"><a href="#mobile-development" aria-controls="mobile development" role="tab" data-toggle="tab">
                                        <h2>Mobile Development</h2>
                                    </a></li>
                                <li role="Products"><a href="#product" aria-controls="product" role="tab" data-toggle="tab">
                                        <h2>Products</h2>
                                    </a></li>
                            </ul>
                            <div class="tab-content mytab">
                                <div role="tabpanel" class="tab-pane active" id="digital-marketing">
                                    <ul class="list-unstyled">
                                        <li class="col span_4">
                                            <div class="row">
                                                <div class="box">
                                                    <div class="col span_4"> <img src="./assets/images/digital_marketing.png" class="img-responsive center-block" alt="Digital Marketing"></div>
                                                    <div class="col span_8"> <a href="digital-marketing/">digital marketing</a>
                                                        <div class="line"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li class="col span_4">
                                            <div class="row">
                                                <div class="box">
                                                    <div class="col span_4"> <img src="./assets/images/seo_icon.png" alt="Seo Services" class="img-responsive center-block"></div>
                                                    <div class="col span_8"> <a href="seo-services/">Seo Services</a>
                                                        <div class="line"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li class="col span_4">
                                            <div class="row">
                                                <div class="box">
                                                    <div class="col span_4"> <img src="./assets/images/social-media-marketing-Icon.png" alt="Social Media Marketing" class="img-responsive center-block"></div>
                                                    <div class="col span_8"> <a href="social-media-marketing/">Social Media Marketing</a>
                                                        <div class="line"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li class="col span_4">
                                            <div class="row">
                                                <div class="box">
                                                    <div class="col span_4"> <img src="./assets/images/reputation-brand-management-icon.png" alt="Reputation & Brand Management" class="img-responsive center-block"></div>
                                                    <div class="col span_8"> <a href="reputation-brand-management/">Reputation & Brand Management</a>
                                                        <div class="line"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li class="col span_4">
                                            <div class="row">
                                                <div class="box">
                                                    <div class="col span_4"> <img src="./assets/images/mobile-app-marketing-icon.png" alt="Mobile App Marketing" class="img-responsive center-block"></div>
                                                    <div class="col span_8"> <a href="mobile-app-marketing/">Mobile App Marketing</a>
                                                        <div class="line"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li class="col span_4">
                                            <div class="row">
                                                <div class="box">
                                                    <div class="col span_4"> <img src="./assets/images/content-marketing-icon.png" alt="Content Marketing" class="img-responsive center-block"></div>
                                                    <div class="col span_8"> <a href="content-marketing/">Content Marketing</a>
                                                        <div class="line"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li class="col span_4">
                                            <div class="row">
                                                <div class="box">
                                                    <div class="col span_4"> <img src="./assets/images/event-marketing-promotion-icon.png" alt="Event Marketing & Promotion" class="img-responsive center-block"></div>
                                                    <div class="col span_8"> <a href="event-marketing-promotion/">Event Marketing & Promotion</a>
                                                        <div class="line"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li class="col span_4">
                                            <div class="row">
                                                <div class="box">
                                                    <div class="col span_4"> <img src="./assets/images/amazon-icon.png" alt="Product Listing Optimization" class="img-responsive center-block"></div>
                                                    <div class="col span_8"> <a href="amazon-product-listing-optimization/">Product Listing Optimization</a>
                                                        <div class="line"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div role="tabpanel" class="tab-pane" id="web-development">
                                    <ul class="list-unstyled">
                                        <li class="col span_4">
                                            <div class="row">
                                                <div class="box">
                                                    <div class="col span_4"> <img src="./assets/images/web-development-icon.png" alt="web development" class="img-responsive center-block"></div>
                                                    <div class="col span_8"> <a href="webdevelopment/">web development</a>
                                                        <div class="line"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li class="col span_4">
                                            <div class="row">
                                                <div class="box">
                                                    <div class="col span_4"> <img src="./assets/images/web-application-development-icon.png" alt="web application development" class="img-responsive center-block"></div>
                                                    <div class="col span_8"> <a href="web-application-development/">web application development</a>
                                                        <div class="line"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li class="col span_4">
                                            <div class="row">
                                                <div class="box">
                                                    <div class="col span_4"> <img src="./assets/images/e-commerce-development-icon.png" alt="e-commerce development" class="img-responsive center-block"></div>
                                                    <div class="col span_8"> <a href="e-commerce-development/">e-commerce development</a>
                                                        <div class="line"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li class="col span_4">
                                            <div class="row">
                                                <div class="box">
                                                    <div class="col span_4"> <img src="./assets/images/web-maintenance-icon.png" alt="Web Maintenance" class="img-responsive center-block"></div>
                                                    <div class="col span_8"> <a href="web-maintenance/">Web Maintenance</a>
                                                        <div class="line"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li class="col span_4">
                                            <div class="row">
                                                <div class="box">
                                                    <div class="col span_4"> <img src="./assets/images/content-management-system-icon.png" alt="content management system" class="img-responsive center-block"></div>
                                                    <div class="col span_8"> <a href="content-management-system/">content management system</a>
                                                        <div class="line"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li class="col span_4">
                                            <div class="row">
                                                <div class="box">
                                                    <div class="col span_4"> <img src="./assets/images/web-design-icon.png" alt="web design" class="img-responsive center-block"></div>
                                                    <div class="col span_8"> <a href="web-design/">web design</a>
                                                        <div class="line"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div role="tabpanel" class="tab-pane" id="mobile-development">
                                    <ul class="list-unstyled">
                                        <li class="col span_4">
                                            <div class="row">
                                                <div class="box">
                                                    <div class="col span_4"> <img src="./assets/images/Mobile-App-Icon.png" alt="Mobile App Development" class="img-responsive center-block"></div>
                                                    <div class="col span_8"> <a href="mobiledevelopment/mobile-app-development/">Mobile App Development</a>
                                                        <div class="line"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li class="col span_4">
                                            <div class="row">
                                                <div class="box">
                                                    <div class="col span_4"> <img src="./assets/images/M-Commerce-icon.png" alt="mCommerce Development" class="img-responsive center-block"></div>
                                                    <div class="col span_8"> <a href="mobiledevelopment/m-commerce-development/">mCommerce Development</a>
                                                        <div class="line"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li class="col span_4">
                                            <div class="row">
                                                <div class="box">
                                                    <div class="col span_4"> <img src="./assets/images/android-app-development.png" alt="Android App Development" class="img-responsive center-block"></div>
                                                    <div class="col span_8"> <a href="mobiledevelopment/android-app-development/">Android App Development</a>
                                                        <div class="line"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li class="col span_4">
                                            <div class="row">
                                                <div class="box">
                                                    <div class="col span_4"> <img src="./assets/images/ios-development-icon.png" alt="iOS App Development" class="img-responsive center-block"></div>
                                                    <div class="col span_8"> <a href="mobiledevelopment/ios-application-development/">iOS App Development</a>
                                                        <div class="line"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div role="tabpanel" class="tab-pane" id="product">
                                    <ul class="list-unstyled">
                                        <li class="col span_4">
                                            <div class="row">
                                                <div class="box">
                                                    <div class="col span_4"> <img src="./assets/images/security-surelliance-icon.png" alt="Security Surveillance" class="img-responsive center-block"></div>
                                                    <div class="col span_8"> <a href="product-category/security-surveillance/">Security Surveillance</a>
                                                        <div class="line"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li class="col span_4">
                                            <div class="row">
                                                <div class="box">
                                                    <div class="col span_4"> <img src="./assets/images/ups-system-icon.png" alt="UPS System" class="img-responsive center-block"></div>
                                                    <div class="col span_8"> <a href="product-category/ups-system/">UPS System</a>
                                                        <div class="line"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li class="col span_4">
                                            <div class="row">
                                                <div class="box">
                                                    <div class="col span_4"> <img src="./assets/images/mini-projector-icon.png" alt="Mini projector" class="img-responsive center-block"></div>
                                                    <div class="col span_8"> <a href="product-category/mini-projector/">Mini projector</a>
                                                        <div class="line"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li class="col span_4">
                                            <div class="row">
                                                <div class="box">
                                                    <div class="col span_4"> <img src="./assets/images/ethernet-ports-switches-icon.png" alt="Ethernet Ports & Switches" class="img-responsive center-block"></div>
                                                    <div class="col span_8"> <a href="product-category/ethernet-ports-switches/">Ethernet Ports & Switches</a>
                                                        <div class="line"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li class="col span_4">
                                            <div class="row">
                                                <div class="box">
                                                    <div class="col span_4"> <img src="./assets/images/space-box-icon.png" alt="Space Box" class="img-responsive center-block"></div>
                                                    <div class="col span_8"> <a href="product-category/space-box/">Space Box</a>
                                                        <div class="line"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </article>
                </div>
            </div>
        </section> -->
        <div class="clearfix"></div>
        <section class="processtitle">
            <div class="container">
                <h2 class="titlebox">OUR PROCESS</h2>
            </div>
        </section>
        <section class="process"> <img src="./assets/images/server-homepage-banner.jpg" alt="Server Center Process images" class="img-responsive" />
            <div class="clearfix"></div>
        </section>
        <div class="clearfix"></div>
        <section class="whychoose"><img src="./assets/images/why-choos.png" alt="why-choos" class="img-responsive" /></section>
        <section class="whychoosetest">
            <div class="container">
                <div class="row">
                    <ul class="list-unstyled">
                        <li class="col span_6">
                            <p>We believe in realistic approach and don’t promise extraordinary figures like top 10 rankings in Yahoo and Google Though the majority of our clients are listed among the top ten ranks. But yes, we promise to get the respectable rankings for your business.</p>
                        </li>
                        <li class="col span_6">
                            <p>We have professional expertise both in the organic marketing (Search Engine Optimization) and the paid marketing and advertising (Pay Per Click). We are Google certified partners for Analytics and AdWords.</p>
                        </li>
                        <div class="clearfix"></div>
                        <li class="col span_6">
                            <p>Our experience of more than a decade and a team of qualified SEO professionals are the major assets. We believe that knowledge is the ever evolving process, hence we keep our self updated with the latest market trends to deliver the desired output.</p>
                        </li>
                        <li class="col span_6">
                            <p> Some of our remarkable accolades include: the #3 position in the list of top SEO companies for the year 2010, and #5 for the year 2009 by TopSEOs. We were also awarded “Consistent Top Performer“for two consecutive years 2009 and 2011.</p>
                        </li>
                        <li class="col span_6">
                            <p>We have a huge record of success stories attributed to our global recognition. We have more than 130 satisfied clients and have a whopping retention rate of 96.7%.</p>
                        </li>
                    </ul>
                    <div class="col span_12">
                        <p class="choose">No wonder, Server Center has created a niche for itself among top companies for SEO Services India. Sign up for one of SEO Packages and watch your keyword rankings improve.</p>
                    </div>
                </div>
            </div>
        </section>
    </main>
    <div class="clearfix"></div>


    <app-testimonials></app-testimonials>
    <app-latestblog></app-latestblog>
    