import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-latestblog',
  templateUrl: './latestblog.component.html',
  styleUrls: ['./latestblog.component.css']
})
export class LatestblogComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
