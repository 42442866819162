
        <div class="banner"> <img src="./assets/images/service-banner.jpg" alt="ServerCenter Services" class="img-responsive center-block"></div>
        <main>
            <section class="breadcrumb-section">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <ul class="breadcrumb">
                                <li><a href="../">Home</a></li>
                                <li class="active">Services</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            <div class="clearfix"></div>
            <app-our-services></app-our-services>
        </main>
        <div class="clearfix"></div>
        <app-testimonials></app-testimonials>
    <app-latestblog></app-latestblog>