    <header class="webHeader">
        <div class="container">
            <div class="row">
                <div class="col span_2"><a class="navbar-brand" href="/"><img src="./assets/images/logo.png" alt="ServerCenter Logo" class="img-responsive logo"></a></div>
                <div class="col span_10">
                    <nav class="navbar navbar-default">
                        <ul id="menu-main-navigation" class="nav">
                            <li class="menu-item"><a title="Home" routerLink="/" routerLinkActive="active">Home</a></li>
                            <li id="menu-item-102" class="menu-item"><a title="Services" routerLink="services"  class="dropdown-toggle">Services <span class="caret"></span></a>
                                <ul class=" dropdown-menu">
                                    <li class="menu-item"><a title="Digital Marketing" href="digital-marketing/" class="dropdown-toggle">Digital Marketing</a>
                                        <ul role="menu" class=" dropdown-menu">
                                            <li id="menu-item-273" class="menu-item"><a title="SEO Services" href="digital-marketing/seo-services/">SEO Services</a></li>
                                            <li id="menu-item-274" class="menu-item"><a title="Social Media Marketing" href="digital-marketing/social-media-marketing/">Social Media Marketing</a></li>
                                            <li id="menu-item-271" class="menu-item"><a title="Reputation &#038; Brand Management" href="digital-marketing/reputation-brand-management/">Reputation &#038; Brand Management</a></li>
                                            <li id="menu-item-269" class="menu-item"><a title="Mobile App Marketing" href="digital-marketing/mobile-app-marketing/">Mobile App Marketing</a></li>
                                            <li id="menu-item-267" class="menu-item"><a title="Content Marketing" href="digital-marketing/content-marketing/">Content Marketing</a></li>
                                            <li id="menu-item-268" class="menu-item"><a title="Event Marketing &#038; Promotion" href="digital-marketing/event-marketing-promotion/">Event Marketing &#038; Promotion</a></li>
                                            <li id="menu-item-290" class="menu-item"><a title="Amazon Product Listing Optimization" href="amazon-product-listing-optimization/">Amazon Product Listing Optimization</a></li>
                                        </ul>
                                    </li>
                                    <li id="menu-item-263" class="menu-item menu-item-type-post_type_archive menu-item-object-webdevelopment menu-item-has-children menu-item-263 dropdown-submenu"><a title="Web Development" href="webdevelopment/" class="dropdown-toggle">Web Development</a>
                                        <ul role="menu" class=" dropdown-menu">
                                            <li id="menu-item-278" class="menu-item"><a title="Web Design" href="webdevelopment/web-design/">Web Design</a></li>
                                            <li id="menu-item-277" class="menu-item"><a title="Web Application Development" href="webdevelopment/web-application-development/">Web Application Development</a></li>
                                            <li id="menu-item-276" class="menu-item"><a title="E-Commerce Development" href="webdevelopment/e-commerce-development/">E-Commerce Development</a></li>
                                            <li id="menu-item-279" class="menu-item"><a title="Web Maintenance" href="webdevelopment/web-maintenance/">Web Maintenance</a></li>
                                            <li id="menu-item-275" class="menu-item"><a title="Content Management System (CMS)" href="webdevelopment/content-management-system/">Content Management System (CMS)</a></li>
                                        </ul>
                                    </li>
                                    <li id="menu-item-372" class="menu-item dropdown-submenu"><a title="Mobile Development" href="#" class="dropdown-toggle">Mobile Development</a>
                                        <ul role="menu" class=" dropdown-menu">
                                            <li id="menu-item-388" class="menu-item"><a title="Mobile App Development" href="mobiledevelopment/mobile-app-development/">Mobile App Development</a></li>
                                            <li id="menu-item-385" class="menu-item"><a title="Android App Development" href="mobiledevelopment/android-app-development/">Android App Development</a></li>
                                            <li id="menu-item-387" class="menu-item"><a title="m-Commerce Development" href="mobiledevelopment/m-commerce-development/">m-Commerce Development</a></li>
                                            <li id="menu-item-386" class="menu-item"><a title="IOS Application Development" href="mobiledevelopment/ios-application-development/">IOS Application Development</a></li>
                                        </ul>
                                    </li>
                                    <li id="menu-item-266" class="menu-item dropdown-submenu"><a title="Product" href="#" class="dropdown-toggle">Product</a>
                                        <ul role="menu" class=" dropdown-menu">
                                            <li id="menu-item-580" class="menu-item"><a title="Security Surveillance" href="product-category/security-surveillance/">Security Surveillance</a></li>
                                            <li id="menu-item-581" class="menu-item"><a title="UPS System" href="product-category/ups-system/">UPS System</a></li>
                                            <li id="menu-item-584" class="menu-item"><a title="Mini Projector" href="product-category/mini-projector/">Mini Projector</a></li>
                                            <li id="menu-item-582" class="menu-item"><a title="Ethernet Ports &amp; Switches" href="product-category/ethernet-ports-switches/">Ethernet Ports &#038; Switches</a></li>
                                            <li id="menu-item-395" class="menu-item"><a title="Space Box" href="product-category/space-box/">Space Box</a></li>
                                        </ul>
                                    </li>
                                    <li id="menu-item-619" class="menu-item"><a title="Data Center" href="data-center/">Data Center</a></li>
                                </ul>
                            </li>
                            <li id="menu-item-151" class="menu-item"><a title="Process" routerLink="process">Process</a></li>
                            <li id="menu-item-407" class="menu-item"><a title="Portfolio" href="portfolio/">Portfolio</a></li>
                            <li id="menu-item-21" class="menu-item"><a title="About Us" routerLink="about">About Us</a></li>
                            <li id="menu-item-24" class="menu-item"><a title="Contact Us" href="contact-us/">Contact Us</a></li>
                            <li id="menu-item-30" class="menu-item"><a title="Get a Quote" href="get-a-quote/">Get a Quote</a></li>
                            <li id="menu-item-210" class="menu-item"><a title="Blog" href="blog/">Blog</a></li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    </header>
