import { Component, ChangeDetectionStrategy } from '@angular/core';
import { LyTheme2 } from '@alyle/ui';

const STYLES = ({
  carousel: {
    margin: 'auto',
    maxWidth: '100%',
    height: 'auto',
  },
  carouselItem: {
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'flex-end',
    alignItems: 'center',
    height: '100%',
    flexDirection: 'column',
    padding: '0',
    boxSizing: 'border-box',
    color: '#fff',
    '&:nth-child(3)': {
      color: '#2b2b2b'
    }
  }
});

@Component({
  selector: 'app-home-banner',
  templateUrl: './home-banner.component.html',
  styleUrls: ['./home-banner.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class HomeBannerComponent {

  readonly classes = this.theme.addStyleSheet(STYLES);
  items = [
    {
      title: 'Mountains',
      img: './assets/images/banner/Banner1.jpg'
    },
    {
      title: 'Four Lakes, Queshuachaca',
      img: './assets/images/banner/Banner2.jpg'
    },
    {
      title: 'Mountains',
      img: './assets/images/banner/Banner3.jpg'
    },
    {
      title: 'Mountains',
      img: './assets/images/banner/Banner4.jpg'
    }
  ];

  constructor(private theme: LyTheme2) { }

}