import { Component, ChangeDetectionStrategy } from '@angular/core';
import { LyTheme2 } from '@alyle/ui';

const STYLES = ({
  carousel: {
    margin: 'auto',
    maxWidth: '100%',
    height: 'auto',
  },
  carouselItem: {
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'flex-end',
    alignItems: 'center',
    height: '100%',
    flexDirection: 'column',
    padding: '0',
    boxSizing: 'border-box',
    color: '#fff'
    
  }
});

@Component({
  selector: 'app-testimonials',
  templateUrl: './testimonials.component.html',
  styleUrls: ['./testimonials.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TestimonialsComponent {

  readonly classes = this.theme.addStyleSheet(STYLES);
  items = [
    {
      author: 'Nolan',
      comment: 'They transformed my existing sluggish website into an interactive and appealing website. The traffic on my website increased and ever since that my business never looked back.'
    },
    {
      author: 'Vincent',
      comment: 'Not only their services, but even their customer care support is simply outstanding. Unlike other service providers, they educate you about what they are doing.'
    },
    {
      author: 'Nathan',
      comment: 'My online store was on the verge of getting shut down. They arrived at the right time and through their efficient services, my business witnessed a never before seen growth. I really thank the talented engineers of Server Center to charge my business.'
    },
    {
      author: 'Eliott',
      comment: 'Availing their services was the best decision and investment for my business. Before approaching them my online business was struggling to have sufficient website traffic, but today, it has global customers.'
    }
  ];

  constructor(private theme: LyTheme2) { }

}
