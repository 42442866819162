<div class="banner"> <img src="./assets/images/our-process.png" alt="ServerCenter Process images" class="img-responsive center-block"></div>
        <main>
            <div class="clearfix"></div>
            <section class="processtitle">
                <div class="container">
                    <div class="col-md-12">
                        <div class="titlebox">
                            <h1> OUR PROCESS</h1>
                        </div>
                    </div>
                </div>
            </section>
            <section class="process"> <img src="./assets/images/server-homepage-banner.jpg" alt="ServerCenter Process images" class="img-responsive" />
                <div class="clearfix"></div>
            </section>
        </main>
        <div class="clearfix"></div>
        <app-testimonials></app-testimonials>
    <app-latestblog></app-latestblog>