<section class="testimonial">
    <div class="clearfix"></div>
    <div class="container">
        <div class="row">
            <div class="col span_12 latest-blog">
                <h2> What our Client <span> ARE SAYING</span></h2>
            </div>
            <div class="col span_12">
                <div class="subcontainer">
                    <ly-carousel
                        [withClass]="classes.carousel"
                        touch
                        >
                        <ly-carousel-item
                        *ngFor="let item of items"
                        [withClass]="classes.carouselItem"
                        >

                        <div class="box text-center">
                            <blockquote>
                                <p>{{item.comment}}</p> <small> {{item.author}} </small>
                            </blockquote>
                        </div>

                        </ly-carousel-item>
                    </ly-carousel>
                </div>
            </div>
        </div>
    </div>
</section>