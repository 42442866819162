<section class="ourServices">
<div class="container">
<div ly-paper bg="background:secondary">
    <ly-tabs class="customTab" [selectedIndex]="1">
      <ly-tab>
        <button ly-tab-label class="tabLink">DIGITAL MARKETING</button>
        <div [withClass]="classes.content" lyTyp="body1">
            <ul class="list-unstyled tabContent">
                <li class="col span_4">
                    <div class="row">
                        <div class="box">
                            <div class="col span_4"> <img src="./assets/images/digital_marketing.png" class="img-responsive center-block" alt="Digital Marketing"></div>
                            <div class="col span_8"> <a href="digital-marketing/">digital marketing</a>
                                <div class="line"></div>
                            </div>
                        </div>
                    </div>
                </li>
                <li class="col span_4">
                    <div class="row">
                        <div class="box">
                            <div class="col span_4"> <img src="./assets/images/seo_icon.png" alt="Seo Services" class="img-responsive center-block"></div>
                            <div class="col span_8"> <a href="seo-services/">Seo Services</a>
                                <div class="line"></div>
                            </div>
                        </div>
                    </div>
                </li>
                <li class="col span_4">
                    <div class="row">
                        <div class="box">
                            <div class="col span_4"> <img src="./assets/images/social-media-marketing-Icon.png" alt="Social Media Marketing" class="img-responsive center-block"></div>
                            <div class="col span_8"> <a href="social-media-marketing/">Social Media Marketing</a>
                                <div class="line"></div>
                            </div>
                        </div>
                    </div>
                </li>
                <li class="col span_4">
                    <div class="row">
                        <div class="box">
                            <div class="col span_4"> <img src="./assets/images/reputation-brand-management-icon.png" alt="Reputation & Brand Management" class="img-responsive center-block"></div>
                            <div class="col span_8"> <a href="reputation-brand-management/">Reputation & Brand Management</a>
                                <div class="line"></div>
                            </div>
                        </div>
                    </div>
                </li>
                <li class="col span_4">
                    <div class="row">
                        <div class="box">
                            <div class="col span_4"> <img src="./assets/images/mobile-app-marketing-icon.png" alt="Mobile App Marketing" class="img-responsive center-block"></div>
                            <div class="col span_8"> <a href="mobile-app-marketing/">Mobile App Marketing</a>
                                <div class="line"></div>
                            </div>
                        </div>
                    </div>
                </li>
                <li class="col span_4">
                    <div class="row">
                        <div class="box">
                            <div class="col span_4"> <img src="./assets/images/content-marketing-icon.png" alt="Content Marketing" class="img-responsive center-block"></div>
                            <div class="col span_8"> <a href="content-marketing/">Content Marketing</a>
                                <div class="line"></div>
                            </div>
                        </div>
                    </div>
                </li>
                <li class="col span_4">
                    <div class="row">
                        <div class="box">
                            <div class="col span_4"> <img src="./assets/images/event-marketing-promotion-icon.png" alt="Event Marketing & Promotion" class="img-responsive center-block"></div>
                            <div class="col span_8"> <a href="event-marketing-promotion/">Event Marketing & Promotion</a>
                                <div class="line"></div>
                            </div>
                        </div>
                    </div>
                </li>
                <li class="col span_4">
                    <div class="row">
                        <div class="box">
                            <div class="col span_4"> <img src="./assets/images/amazon-icon.png" alt="Product Listing Optimization" class="img-responsive center-block"></div>
                            <div class="col span_8"> <a href="amazon-product-listing-optimization/">Product Listing Optimization</a>
                                <div class="line"></div>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>

        </div>
      </ly-tab>
      <ly-tab>
        <button ly-tab-label class="tabLink">Web DEVELOPMENT</button>
        <div [withClass]="classes.content" lyTyp="body1">
          
            <ul class="list-unstyled tabContent">
                <li class="col span_4">
                    <div class="row">
                        <div class="box">
                            <div class="col span_4"> <img src="./assets/images/web-development-icon.png" alt="web development" class="img-responsive center-block"></div>
                            <div class="col span_8"> <a href="webdevelopment/">web development</a>
                                <div class="line"></div>
                            </div>
                        </div>
                    </div>
                </li>
                <li class="col span_4">
                    <div class="row">
                        <div class="box">
                            <div class="col span_4"> <img src="./assets/images/web-application-development-icon.png" alt="web application development" class="img-responsive center-block"></div>
                            <div class="col span_8"> <a href="web-application-development/">web application development</a>
                                <div class="line"></div>
                            </div>
                        </div>
                    </div>
                </li>
                <li class="col span_4">
                    <div class="row">
                        <div class="box">
                            <div class="col span_4"> <img src="./assets/images/e-commerce-development-icon.png" alt="e-commerce development" class="img-responsive center-block"></div>
                            <div class="col span_8"> <a href="e-commerce-development/">e-commerce development</a>
                                <div class="line"></div>
                            </div>
                        </div>
                    </div>
                </li>
                <li class="col span_4">
                    <div class="row">
                        <div class="box">
                            <div class="col span_4"> <img src="./assets/images/web-maintenance-icon.png" alt="Web Maintenance" class="img-responsive center-block"></div>
                            <div class="col span_8"> <a href="web-maintenance/">Web Maintenance</a>
                                <div class="line"></div>
                            </div>
                        </div>
                    </div>
                </li>
                <li class="col span_4">
                    <div class="row">
                        <div class="box">
                            <div class="col span_4"> <img src="./assets/images/content-management-system-icon.png" alt="content management system" class="img-responsive center-block"></div>
                            <div class="col span_8"> <a href="content-management-system/">content management system</a>
                                <div class="line"></div>
                            </div>
                        </div>
                    </div>
                </li>
                <li class="col span_4">
                    <div class="row">
                        <div class="box">
                            <div class="col span_4"> <img src="./assets/images/web-design-icon.png" alt="web design" class="img-responsive center-block"></div>
                            <div class="col span_8"> <a href="web-design/">web design</a>
                                <div class="line"></div>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
      </ly-tab>
      <ly-tab>
        <button ly-tab-label class="tabLink">Mobile Development</button>
        <div [withClass]="classes.content" lyTyp="body1">
            <ul class="list-unstyled tabContent">
                <li class="col span_4">
                    <div class="row">
                        <div class="box">
                            <div class="col span_4"> <img src="./assets/images/Mobile-App-Icon.png" alt="Mobile App Development" class="img-responsive center-block"></div>
                            <div class="col span_8"> <a href="mobiledevelopment/mobile-app-development/">Mobile App Development</a>
                                <div class="line"></div>
                            </div>
                        </div>
                    </div>
                </li>
                <li class="col span_4">
                    <div class="row">
                        <div class="box">
                            <div class="col span_4"> <img src="./assets/images/M-Commerce-icon.png" alt="mCommerce Development" class="img-responsive center-block"></div>
                            <div class="col span_8"> <a href="mobiledevelopment/m-commerce-development/">mCommerce Development</a>
                                <div class="line"></div>
                            </div>
                        </div>
                    </div>
                </li>
                <li class="col span_4">
                    <div class="row">
                        <div class="box">
                            <div class="col span_4"> <img src="./assets/images/android-app-development.png" alt="Android App Development" class="img-responsive center-block"></div>
                            <div class="col span_8"> <a href="mobiledevelopment/android-app-development/">Android App Development</a>
                                <div class="line"></div>
                            </div>
                        </div>
                    </div>
                </li>
                <li class="col span_4">
                    <div class="row">
                        <div class="box">
                            <div class="col span_4"> <img src="./assets/images/ios-development-icon.png" alt="iOS App Development" class="img-responsive center-block"></div>
                            <div class="col span_8"> <a href="mobiledevelopment/ios-application-development/">iOS App Development</a>
                                <div class="line"></div>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
      </ly-tab>
      <ly-tab>
        <button ly-tab-label class="tabLink">Products</button>
        <div [withClass]="classes.content" lyTyp="body1">
            
            <ul class="list-unstyled tabContent">
                <li class="col span_4">
                    <div class="row">
                        <div class="box">
                            <div class="col span_4"> <img src="./assets/images/security-surelliance-icon.png" alt="Security Surveillance" class="img-responsive center-block"></div>
                            <div class="col span_8"> <a href="product-category/security-surveillance/">Security Surveillance</a>
                                <div class="line"></div>
                            </div>
                        </div>
                    </div>
                </li>
                <li class="col span_4">
                    <div class="row">
                        <div class="box">
                            <div class="col span_4"> <img src="./assets/images/ups-system-icon.png" alt="UPS System" class="img-responsive center-block"></div>
                            <div class="col span_8"> <a href="product-category/ups-system/">UPS System</a>
                                <div class="line"></div>
                            </div>
                        </div>
                    </div>
                </li>
                <li class="col span_4">
                    <div class="row">
                        <div class="box">
                            <div class="col span_4"> <img src="./assets/images/mini-projector-icon.png" alt="Mini projector" class="img-responsive center-block"></div>
                            <div class="col span_8"> <a href="product-category/mini-projector/">Mini projector</a>
                                <div class="line"></div>
                            </div>
                        </div>
                    </div>
                </li>
                <li class="col span_4">
                    <div class="row">
                        <div class="box">
                            <div class="col span_4"> <img src="./assets/images/ethernet-ports-switches-icon.png" alt="Ethernet Ports & Switches" class="img-responsive center-block"></div>
                            <div class="col span_8"> <a href="product-category/ethernet-ports-switches/">Ethernet Ports & Switches</a>
                                <div class="line"></div>
                            </div>
                        </div>
                    </div>
                </li>
                <li class="col span_4">
                    <div class="row">
                        <div class="box">
                            <div class="col span_4"> <img src="./assets/images/space-box-icon.png" alt="Space Box" class="img-responsive center-block"></div>
                            <div class="col span_8"> <a href="product-category/space-box/">Space Box</a>
                                <div class="line"></div>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
      </ly-tab>
    </ly-tabs>
  </div>
</div>
</section>