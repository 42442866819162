<section class="letes_section">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <p>want to start a project ?</p>
                <div class="consult"> <a href="get-a-quote/">Let's Consult</a></div>
            </div>
        </div>
    </div>
</section>
<footer class="bg">    
    <div class="container">
        <div class="row">
            <div class="col span_4">
                <h4><span>GLOBAL LOCATIONS</span></h4>
                <ul class="list-unstyled">
                    <li id="canada" itemprop="address" itemscope="" itemtype="http://schema.org/PostalAddress"><a href="https://goo.gl/maps/JhMQnweVhfs" target="_blank"><span itemprop="streetAddress">#201 628 12 Ave SW</span>, <br><span itemprop="addressLocality">Calgary</span>, <span itemprop="addressRegion">AB Canada</span></a></li>
                    <li id="india" itemprop="address" itemscope="" itemtype="http://schema.org/PostalAddress"><a href="https://goo.gl/maps/HFm9K7VVJXAtCpNV6" target="_blank"><span itemprop="streetAddress">Unit #7 Omaxe World Street</span> <br><span itemprop="addressLocality">Sector 79, Faridabad, Haryana 121004 </span><span itemprop="addressRegion"></span></a></li>
                    <li>
                        <div class="topline" itemscope itemtype="http://schema.org/Person"> <a href="tel:+1-403-351-6600" itemprop="telephone"><span class="glyphicon glyphicon-phone-alt" aria-hidden="true"></span>&nbsp;&nbsp;(+1) 403-351-6600</a></div>
                    </li>
                </ul>
            </div>
            <div class="col span_4">
                <h4><span>CORPORATE INFO</span></h4>
                <ul class="list-unstyled middle threeColumn">
                    <li><a href="">Home</a></li>
                    <li><a href="about-us/">About Us</a></li>
                    <li><a href="services/">Services</a></li>
                    <li><a href="process/">Process</a></li>
                    <li><a href="portfolio/">Portfolio</a></li>
                    <li><a href="blog/">Blog</a></li>
                    <li><a href="contact-us/">Contact us</a></li>
                    <li><a href="get-a-quote/">Get A Quote</a></li>
                    <li><a href="terms-comditions/#termcondition">Terms & Conditions</a></li>
                    <li><a href="terms-comditions/#headingEight">Refund/return policy</a></li>
                    <li><a href="terms-comditions/#Shippingpolicy">Shipping policy</a></li>
                    <li><a href="terms-comditions/#PrivacyPolicy">Privacy policy</a></li>
                </ul>
            </div>
            <div class="col span_4">
                <h4><span>EXPERTISE</span></h4>
                <ul class="list-unstyled twoColumn">
                    <li><a href="webdevelopment/">Web Development</a></li>
                    <li><a href="webdevelopment/web-design/">Web Design</a></li>
                    <li><a href="web-application-development/">Web Application Development</a></li>
                    <li><a href="digital-marketing/">Digital marketing</a></li>
                    <li><a href="e-commerce-development/">E-Commerce Development</a></li>
                    <li><a href="digital-marketing/social-media-marketing/">Social Media Marketing</a></li>
                </ul>
            </div>                
        </div>
        <div class="row">
            <div class="col span_12"> <img src="./assets/img/payment-icon.png" alt="payment" class="img-responsive center-block pay"></div>
        </div>            
    </div>

</footer>
<section class="copyright">
    <div class="container">
        <div class="row">
            <div class="col span_12">
                <ul class="list-inline text-center">
                    <li><a href="https://www.facebook.com/servercenter.ca/" target="_blank"><img src="./assets/images/facebook.png" alt="facebook" class="img-responsive"></a></li>
                    <li><a href="https://www.linkedin.com/company/servercenter" target="_blank"><img src="./assets/images/inked.png" alt="Linkedin" class="img-responsive"></a></li>
                    <li><a href="https://twitter.com/serverncenter" target="_blank"><img src="./assets/images/twitter.png" alt="twitter" class="img-responsive"></a></li>
                </ul>
            </div>
        </div>
    </div>
</section>


