<section class="blog">
    <div class="container">
        <div class="row">
            <div class="col span_6">
                <h2 class="latest-blog">Latest <span> Blog</span></h2>
            </div>
            <div class="col span_6">
                <div class="latest-blog viewall text-right"><a href="blog/" class="aurla">View All</a></div>
            </div>
        </div>
        <div class="row">
            <div class="col span_3"> <a href="googles-people-also-asked-for-boxes-the-key-power-players/">
                    <div class="blogdiv card-1"> <img src="./assets/images/1.jpg" alt="Google’s People also asked for boxes the key power players" class="img-responsive" />
                        <div class="card-body">
                            <h5 class="card-title"> Google’s People also asked for boxes the key power players</h5>
                        </div>
                    </div>
                </a></div>
            <div class="col span_3"> <a href="google-knowledge-panel/">
                    <div class="blogdiv card-1"> <img src="./assets/images/2.jpg" alt="Google Knowledge Panel" class="img-responsive" />
                        <div class="card-body">
                            <h5 class="card-title"> Google Knowledge Panel</h5>
                        </div>
                    </div>
                </a></div>
            <div class="col span_3"> <a href="acquire-the-featured-snippet-and-grow-your-business/">
                    <div class="blogdiv card-1"> <img src="./assets/images/3.jpg" alt="Acquire the featured snippet and grow your business" class="img-responsive" />
                        <div class="card-body">
                            <h5 class="card-title"> Acquire the featured snippet and grow your business</h5>
                        </div>
                    </div>
                </a></div>
            <div class="col span_3"> <a href="get-more-information-on-different-types-of-seo/">
                    <div class="blogdiv card-1"> <img src="./assets/images/4.jpg" alt="Get More Information on Different Types of SEO" class="img-responsive" />
                        <div class="card-body">
                            <h5 class="card-title"> Get More Information on Different Types of SEO</h5>
                        </div>
                    </div>
                </a></div>
        </div>
    </div>
</section>