<!-- <div class="banner bns">
    <div id="owlbanner" class="owl-carousel owl-theme">
        <div class="item"> <a href="index.php"> <img src="./assets/images/banner/banner.jpg" alt="Your Idea , Our Expertise"> </a></div>
        <div class="item"> <a href="digital-marketing/"> <img src="./assets/images/banner/banner3.png" alt="Digital Marketing"> </a></div>
        <div class="item"> <a href="webdevelopment/"> <img src="./assets/images/banner/banner4.jpg" alt="website design, Custom web development, web development company"> </a></div>
        <div class="item"> <a href="e-commerce-development/"> <img src="./assets/images/banner/ecommer-development.jpg" alt="Ecommerce Development"> </a></div>
    </div>
</div> -->
<ly-carousel
  [withClass]="classes.carousel"
  touch
>
  <ly-carousel-item
    *ngFor="let item of items"
    [withClass]="classes.carouselItem"
  >
  <img src="{{item.img}}" alt="">
  </ly-carousel-item>
</ly-carousel>